<div class="form-container">
  <p class="know">Queremos saber más sobre ti<br>
    y cómo podemos ayudarte</p>
  <form [formGroup]="form">
    <div class="input-plus-error">
      <label>Nombre</label>
      <input [ngClass]="{
        'input-error':
          form.get('name')?.invalid &&
          form.get('name')?.touched
      }" type="text" formControlName="name" />
      <div *ngIf="
      form.get('name')?.invalid &&
      form.get('name')?.touched
    " class="error-message">
        <p *ngIf="form.get('name')?.errors?.['required']">
          El campo 'Nombre' es obligatorio. Introduce tu nombre.
        </p>
      </div>
    </div>
    <div class="input-plus-error">
      <label>Apellidos</label>
      <input [ngClass]="{
            'input-error':
              form.get('surname')?.invalid &&
              form.get('surname')?.touched
          }" type="text" formControlName="surname" />
      <div *ngIf="
    form.get('surname')?.invalid &&
    form.get('surname')?.touched
  " class="error-message">
        <p *ngIf="form.get('surname')?.errors?.['required']">
          El campo 'Apellidos' es obligatorio. Introduce tus apellidos.
        </p>
      </div>
    </div>
    <div class="input-plus-error">
      <label>E-mail</label>
      <input [ngClass]="{
            'input-error':
              form.get('email')?.invalid &&
              form.get('email')?.touched
          }" type="email" formControlName="email" />
      <div *ngIf="
      form.get('email')?.invalid &&
      form.get('email')?.touched
    " class="error-message">
        <p *ngIf="form.get('email')?.errors?.['required']">
          El campo 'Email' es obligatorio. Introduce un correo electrónico.
        </p>
        <p *ngIf="form.get('email')?.errors?.['email']">
          Introduce una dirección de correo electrónico válida.
        </p>
      </div>
    </div>
    <div class="input-plus-error">
      <label>Teléfono</label>
      <input [ngClass]="{
        'input-error':
          form.get('phone')?.invalid &&
          form.get('phone')?.touched
      }" class="tel" formControlName="phone" placeholder="600 00 00 00" />
      <div *ngIf="
      form.get('phone')?.invalid &&
      form.get('phone')?.touched
    " class="error-message">
    @if (form.get('phone')?.errors?.['required']){
      <p>
        El campo 'Teléfono' es obligatorio. Asegúrate de incluir solo números.
      </p>
    } @else {
      <p>
        Introduce un número de teléfono válido de 9 dígitos.
      </p>
    }
      </div>
    </div>
    <div class="input-plus-error">
      <label>Empresa</label>
      <input [ngClass]="{
        'input-error':
          form.get('company')?.invalid &&
          form.get('company')?.touched
      }" type="text" formControlName="company" />
      <div *ngIf="
      form.get('company')?.invalid &&
      form.get('company')?.touched
    " class="error-message">
        <p *ngIf="form.get('company')?.errors?.['required']">
          El campo 'Empresa' es obligatorio. Indica el nombre de tu empresa.
        </p>
      </div>
    </div>
    <div class="input-plus-error">
      <label>Tengo interés en...</label>
      <textarea maxlength="100" formControlName="comments"></textarea>
      <div class="error-message" *ngIf="form.get('comments')?.value.length === 100">
        <p>Máximo de 100 caracteres alcanzado.</p>
      </div>
      <div *ngIf="
      form.get('comments')?.invalid &&
      form.get('comments')?.touched
    " class="error-message">
        <p>Debes rellenar este campo.</p>
      </div>
    </div>
    <div class="checkbox">
      <input [ngClass]="{'invalid': form.get('acceptsDataTreatment')?.invalid}" type="checkbox"
        formControlName="acceptsDataTreatment" id="acceptsDataTreatment" />
      <p class="p">Autorizo el <span (click)="openPersonalDataTreatment()">tratamiento de datos personales</span></p>
    </div>
    <button class="gold-color-button" [disabled]="!form.valid" (click)="onSubmit()">
      <img *ngIf="form.valid" src="../../../../assets/images/icons/send-vector.svg" alt="" />
      <img *ngIf="!form.valid" src="../../../../assets/images/icons/send-vector-disabled.svg" alt="" />
      <p>Enviar</p>
    </button>
  </form>
</div>
