<div class="help-component">
  <h3>Ayuda</h3>
  <img src="../../../../assets/images/help-pic.png" alt="">
  <p>
    En <span>Denarius</span>, nos esforzamos por ofrecerte la mejor experiencia bancaria.
  </p>
  <p>
    Si tienes alguna duda o <a routerLink="/faqs">pregunta</a> no dudes en ponerte en contacto con nosotros.
  </p>
  <div class="data">
    <div class="circle">
      <img src="../../../../assets/images/icons/call.svg" alt="">
    </div>
    <div class="content-data">
      <p class="title">Atención Telefónica</p>
      <a href="tel:+34660104099" class="weight700 link">660 10 40 99</a>
    </div>
  </div>
  <div class="data">
    <div class="circle">
      <img src="../../../../assets/images/icons/sms.svg" alt="">
    </div>
    <div class="content-data">
      <p class="title">Atención por Correo</p>
      <a href="mailto:atencion.clientes@denarius.es" class="weight700 link">atencion.clientes&#64;denarius.es</a>
    </div>
  </div>
</div>
