import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Business } from '../../models/user/userData';
import { Observable } from 'rxjs';
import { StateRes } from '../../models/onboarding/onboarding';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  /* LLAMADAS COMPARTIDAS (USER-ROLE SERVICE) */
  public getState() {
    return this.http.get<StateRes>(`${environment.companyUrl}/company/me/state`)
  }
  public getProfile() {
    return this.http.get<any>(`${environment.companyUrl}/company/me`)
  }
  public updateProfile(body: any) {
    return this.http.patch(`${environment.companyUrl}/users/me`, body);
  }
  public updateIcon(file: FormData) {
    const url = `${environment.companyUrl}/company/me/avatar`;
    return this.http.patch(url, file);
  }
  public getDocuments(id: string) {
    return this.http.get<any>(`${environment.companyUrl}/${id}/documents/state`)
  }
  public uploadDocument(companyId: string, formData: FormData): Observable<any>{
    return this.http.post<any>(`${environment.companyUrl}/${companyId}/documents`, formData)
  }
  public contractUrl(id: string, body?:any): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/contract/url`, body)
  }
  public verifyContract(id: string, body?:any): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/contract/verify`, body)
  }
  //OTP
  public requestSmsVerify(id: string, body?: any): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/request/sms/verification`, body)
  }
  public verifyOtp(id: string, otp: string): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/verify/phone?otp=${otp}`, id)
  }

  //ONBOARDING
  public createUser(body: Business): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company`, body)
  }

  public getUrlVerifyRepresentative(companyId: string, ok: string, ko: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('ok', ok).set('ko', ko)
    return this.http.post<any>(`${environment.companyUrl}/company/${companyId}/representative/request/document/verification`, null, {params})
  }
  public verifyRepresentative(id: string, body?:any): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/representative/verify/document`, body)
  }
  //OTHERS
  public verifyDocumentation(id: string, body?: any) {
    return this.http.post<any>(`${environment.companyUrl}/company/${id}/verify/document`, body)
  }
  public getDocumnetsState(id: string) {
    return this.http.get<any>(`${environment.companyUrl}/company/${id}/documents/state`)
  }

  public resetPassword(body: {email:string}): Observable<any>{
    return this.http.post<any>(`${environment.companyUrl}/company/reset/password`, body)
  }


  /* LLAMADAS NO COMPARTIDAS */
  //ONBOARDING

  public createEmployee(id: string, body: any): Observable<any> {
    return this.http.post(`${environment.companyUrl}/company/${id}/employee`, body)
  }
  public getEmployees(companyId: string): Observable<any> {
    return this.http.get<any>(`${environment.companyUrl}/company/${companyId}/employees`)
  }

  // Employee KYC2 Verification
  public requestSmsVerification(companyId: string, employeeId: string, body?: any): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/request/sms/verification`, 
      body
    );
  }
  
  public verifyPhone(companyId: string, employeeId: string, otp: string): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/verify/phone?otp=${otp}`, 
      {}
    );
  }
  
  public uploadIdentifier(companyId: string, employeeId: string, formData: FormData): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/identifier/upload`, 
      formData
    );
  }
  
  public verifyIdentifier(companyId: string, employeeId: string, body?: any): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/identifier/verify`, 
      body
    );
  }
  
  //OTHERS
  public deleteCompany(id: string): Observable<any> {
    return this.http.delete(`${environment.companyUrl}/company/${id}`)
  }

}
