import { trigger, state, style, transition, animate} from '@angular/animations';


export const HideShowAnimation = [
    trigger('visibleHidden', [
        state('visible', style({
          'max-height': '500px',/*  'opacity': '1', */ 'visibility': 'visible', 'margin-top': '10px', 'margin-bottom': '10px'
        })
      ),
      state('void, hidden', style({
          'max-height': '0px',/*  'opacity': '0', */ 'visibility': 'hidden', 'margin-top': '-10px'
        })
      ),
      transition('* => visible', animate('500ms')),
      transition('* => void, * => hidden', animate('300ms')),
    ])
]