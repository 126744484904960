import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-form-sent',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './form-sent.component.html',
  styleUrl: './form-sent.component.scss'
})
export class FormSentComponent {

}
