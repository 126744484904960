<div class="content-wrapper">
  <div class="container">
    <img src="../../../../assets/images/icons/check_email.svg" alt="">
    <h1>¡Gracias por ponerte en<br>
      contacto con nosotros! </h1>
    <p>Nuestro equipo revisará tu solicitud<br>
      y se pondrá en contacto contigo<br>
      lo antes posible.<br>
      Si tienes alguna pregunta<br>
      o necesitas ayuda adicional,<br>
      no dudes en contactarnos.</p>
  </div>
  <a routerLink="onboarding/empresas"><p>Ir a la web de <span>denarius</span></p></a>
</div>
