import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from '../../layout/spinner/spinner.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  public modalMessage! : string
  public modalOption! : string
  public hasInput!: boolean;
  action: Function | undefined;
  public inputValue: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<SpinnerComponent>) {
    this.modalMessage = this.data.modalMessage;
    this.modalOption = this.data.modalOption;
    this.action = this.data.action;
    this.hasInput = this.data.inputRequired
  }


  customAction() {
    if (this.action) {
      this.action(this.hasInput? this.inputValue : null);
    }
    this.dialogRef.close()
  }
}
