import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AccountMovements, AccountTransfer } from "../../models/accounts/account-movements";
import { StateRes } from "../../models/onboarding/onboarding";
import { FavAccount } from "../../models/transfers/transfers";
import { AccountsList, ChartData, UserData } from "../../models/user/userData";
import { UserDataAbstractService } from "./userData.abstract-service";



@Injectable({
  providedIn: 'root',
})
export class UserService implements UserDataAbstractService {
  constructor(private http: HttpClient) { }

  /* PROVISIONAL, VA A INDIVIDUALSERVICE */
  public createUser(body: UserData): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users`, body, { headers: { 'Content-Type': 'application/json' } })
  }
  public getUser(id: string): Observable<UserData> {
    return this.http.get<UserData>(`${environment.userUrl}/users/${id}`);
  }
  public getState() {
    return this.http.get<StateRes>(`${environment.userUrl}/users/me/state`)
  }
  public requestSmsVerify(id: string, body?: any): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/request/sms/verification`, body)
  }

  public verifyOtp(id: string, otp: string): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/verify/phone?otp=${otp}`, id)
  }

  public generateContract(id: string): Observable<Blob> {
    return this.http.post(`${environment.userUrl}/users/${id}/contract`, null, { responseType: 'blob' })
  }
  public signContract(id: string): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/sign`, id)
  }
  public signNewContract(id: string, accountId:string, body:any): Observable<any> {
    return this.http.post<any>(`${environment.coreUrl}/${id}/accounts/${accountId}/contract`, body)
  }
  public getProfile() {
    return this.http.get<any>(`${environment.userUrl}/users/me`)
  }
  public updateProfile(body: any) {
    return this.http.patch(`${environment.userUrl}/users/me`, body);
  }
  public updateUserField(id: string, body: any) {
    return this.http.patch(`${environment.userUrl}/users/${id}`, body);
  }
  public updateIcon(file: FormData) {
    const url = `${environment.userUrl}/users/me/avatar`;
    return this.http.patch(url, file);
  }
  public electronicIdRedirectTo(id: string, ok: string, ko: string, body?: any) {
    let params = new HttpParams();
    params = params.set('ok', ok).set('ko', ko)
    return this.http.post<any>(`${environment.userUrl}/users/${id}/request/document/verification`, null, {params})
  }
  public verifyDocumentation(id: string, body?: any) {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/verify/document`, body)
  }
  public uploadDocument(companyId: string, formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/${companyId}/documents`, formData)
  }
  public getUserDocuments(id: string) {
    return this.http.get<any>(`${environment.userUrl}/${id}/documents/state`)
  }

  public downloadMovement(userId: string, accountId: string, movementId: string, operationNumber: number): Observable<Blob> {
    let queryParam = new HttpParams()
    queryParam = queryParam.set("operationNumber", operationNumber);
    return this.http.get<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/movement/${movementId}/receipt`, { params: queryParam, responseType: 'blob' as 'json' },)
  };

  public getMovementsBusiness(userId: string | null, accountId: string | null, params?: any): Observable<any> {
    return this.http.get<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/movements`, {params})
  }

  //ONBOARDING
  public getDashboard() {
    return this.http.get<UserData>(`${environment.coreUrl}/users/dashboard`);
  }
  public getAccounts(userId: string) {
    return this.http.get<AccountsList[]>(`${environment.coreUrl}/${userId}/accounts`)
  }
  public getAccount(id: string) {
    return this.http.get<AccountsList>(`${environment.coreUrl}/users/accounts/${id}`)
  }
  public getMovements(accountId: string, params?: any) {
    return this.http.get<AccountMovements[]>(`${environment.coreUrl}/users/accounts/${accountId}/movements`, { params })
  }
  public getTransfers(accountId: string, params?: HttpParams) {
    return this.http.get<AccountMovements[]>(`${environment.coreUrl}/users/accounts/${accountId}/transfers`, { params })
  }
  public getTransferDetail(userId: string, id: string, transferId: string) {
    return this.http.get<AccountTransfer>(`${environment.coreUrl}/${userId}/accounts/${id}/transfers/${transferId}`)
  }
  public getMovementDetail(userId: string, id: string, movementId: string, operationNumber: number) {
    let queryParam = new HttpParams()
    queryParam = queryParam.set("operationNumber", operationNumber);
    return this.http.get<AccountMovements>(`${environment.coreUrl}/${userId}/accounts/${id}/movements/${movementId}`, { params: queryParam })
  }

  public createFavAccount(accountId: string, body: FavAccount) {
    return this.http.post<FavAccount>(`${environment.coreUrl}/users/accounts/${accountId}/inst`, body)
  }

  public getFavAccounts(accountId: string) {
    return this.http.get<FavAccount[]>(`${environment.coreUrl}/users/accounts/${accountId}/inst`)
  }

  public deleteFavAccount(accountId: string, favAccountId: string) {
    return this.http.delete<FavAccount>(`${environment.coreUrl}/users/accounts/${accountId}/inst/${favAccountId}`)
  }

  public confirmInst(operationPath: string, body?: any) {
    return this.http.post<any>(`${environment.coreUrl}${operationPath}`, body)
  }

  public getOwnershipCertificate(id: string, accountId: string): Observable<Blob> {
    return this.http.get(`${environment.coreUrl}/${id}/accounts/${accountId}/certificate/ownership`, {
      headers: new HttpHeaders({
        'Accept': 'application/pdf'
      }),
      responseType: 'blob'
    });
  }
  public getBalanceCertificate(id: string, accountId: string, params?: any): Observable<any> {
    return this.http.get(`${environment.coreUrl}/${id}/accounts/${accountId}/certificate/balance`);
  } 
  public changeAccountAlias(userId: string, accountId: string, alias: string): Observable<any>{
    return this.http.post<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/alias`, {alias: alias})
  }
  public contractUrl(id: string, body?: any): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/contract/url`, body)
  }
  public verifyContract(id: string, body?: any): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users/${id}/contract/verify`, body)
  }
  public verifyNewContract(id: string,accountId:string): Observable<any> {
    return this.http.post<any>(`${environment.coreUrl}/${id}/accounts/${accountId}/contract/verify`, null)
  }
  public getDocumentState(userId: string): Observable<any>{
    return this.http.get<any>(`${environment.userUrl}/${userId}/documents/state`)
  }
  public changePassword(userId: string, body: {password:string, rePassword:string}): Observable<any>{
    return this.http.post<any>(`${environment.userUrl}/users/${userId}/change/password`, body)
  }
  public resetPassword(body: {email:string}): Observable<any>{
    return this.http.post<any>(`${environment.userUrl}/users/reset/password`, body)
  }

// Llamadas para el chart de dashboard
public getChartData(period: 'week' | 'month' | 'quarter'): Observable<ChartData> {
  let params = new HttpParams();
  if (period) {
    params = params.set('period', period);
  }

  return this.http.get<ChartData>(`${environment.coreUrl}/users/dashboard/chart`, { params });
}

}
