<div class="chart-container">
    <div *ngIf="!hasChartLoaded" class="skeleton">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{ 'background-color': 'var(--brand-palette-50)', height: '220px', 'margin-bottom': '20px' }">
        </ngx-skeleton-loader>
      </div>
  <div class="graph" *ngIf="hasChartLoaded">
      <div class="topSection">
          <div class="period-selector">
              <button (click)="setPeriod('week')" [class.active]="selectedPeriod === 'week'">Última semana</button>
              <button (click)="setPeriod('month')" [class.active]="selectedPeriod === 'month'">Último mes</button>
              <button (click)="setPeriod('quarter')" [class.active]="selectedPeriod === 'quarter'">Último trimestre</button>
          </div>
      </div>
      <div class="chart">
          <canvas id="myChart"></canvas>
      </div>
      <div class="summary">
        <div class="summary-box">
            <div class="summary-icon">
                <img src="../../../../assets/images/icons/Icon_sendmoney.svg" alt="Ingresos">
            </div>
            <div class="summary-info">
                <span class="summary-title">Ingresos</span>
                <span class="summary-amount positive">
                    {{ (selectedPeriod === 'week' ? chartData?.week?.totalIncome : 
                        selectedPeriod === 'month' ? chartData?.month?.totalIncome : 
                        chartData?.quarter?.totalIncome) | currency: 'EUR' : 'symbol' : '1.2-2' : 'es' }}
                </span>
            </div>
        </div>
        <div class="summary-box">
            <div class="summary-icon">
                <img src="../../../../assets/images/icons/money-recive.svg" alt="Gastos">
            </div>
            <div class="summary-info">
                <span class="summary-title">Gastos</span>
                <span class="summary-amount negative">
                    {{ (selectedPeriod === 'week' ? chartData?.week?.totalExpenses : 
                        selectedPeriod === 'month' ? chartData?.month?.totalExpenses : 
                        chartData?.quarter?.totalExpenses) | currency: 'EUR' : 'symbol' : '1.2-2' : 'es' }}
                </span>
            </div>
        </div>
      </div>
  </div>
</div>
 