import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import localeEs from '@angular/common/locales/es'
import { UserService } from 'src/app/core/services/userArea/user.service';
import { downloadFile } from '../../../core/extensions/download-files';
import { AccountsList, CardsList } from 'src/app/core/models/user/userData';
import { CardCancelComponent } from '../../card/card-cancel/card-cancel.component';
import { CardDetail } from 'src/app/core/models/card/cards';
registerLocaleData(localeEs, 'es');



@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})

export class DetailComponent {

  public accountData!: AccountsList;
  public cardData!: CardDetail;
  public accountTable!: any
  public cardTable!: any
  public limitsTable!: any
  public displayedColumns: string[] = ['item', 'cost'];
  public userName!: string;
  public surname!: string;
  public userId!: string;
  public isLimits: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private auth: AuthService, private datePipe: DatePipe, private dialog: MatDialog, private currencyPipe: CurrencyPipe, private certificateService: UserService) {
    this.userName = sessionStorage.getItem('userName')!
    this.surname = sessionStorage.getItem('surname')!
    this.userId = sessionStorage.getItem('userId')!
    this.accountData = data.accountData
    this.isLimits = data.isLimits ?? false;
    
    if (data.accountData) {
      this.accountData = data.accountData;
      this.accountTable = this.buildAccountTable(this.accountData);
    }
    
    if (data.cardData && !this.isLimits) {
      this.cardData = data.cardData;
      this.cardTable = this.buildCardTable(this.cardData);
    } else if (data.cardData && this.isLimits) {
      this.cardData = data.cardData;
      this.limitsTable = this.buildLimitsTable(this.cardData);
    }

  }

  get formattedExpiration(): string {
    if (!this.cardData?.expiration || this.cardData.expiration.length !== 4) {
      return '';
    }
  
    const year = this.cardData.expiration.substring(0, 2); 
    const month = this.cardData.expiration.substring(2, 4); 
  
    return `${month}/${year}`;
  }

  openToCancel() {
      this.dialog.open(CardCancelComponent, {
        disableClose: true,
        data: {
          cardData: this.cardData,
        },
      });
    }

  buildAccountTable(accountData: AccountsList) {
    return [
      { title: 'Titular', value: this.userName + ' ' + (this.surname || '') },
      { title: 'IBAN', value: accountData.iban },
      { title: 'Alias', value: accountData.alias },
      { title: 'Moneda', value: accountData.currency },
      { title: 'Fecha de apertura', value: this.datePipe.transform(accountData.openingDate, 'dd-MM-YYYY') },
      { title: 'Fecha última operación', value: this.datePipe.transform(accountData.lastOperationDate, 'dd-MM-YYYY') },
      { title: 'Saldo', value: this.currencyPipe.transform(accountData.balance, '€', 'symbol', '1.2-2', 'es') },
      { title: 'Disponible', value: this.currencyPipe.transform(accountData.availableBalance, '€', 'symbol', '1.2-2', 'es') },
      { title: 'Código BIC/SWIFT', value: accountData.swift },
    ];
  }

  buildCardTable(cardData: CardDetail) {
    return [
      { title: 'Titular de la tarjeta', value: cardData.name },
      { title: 'Número de cuenta asociada', value: cardData.account?.iban },
      { title: 'Alias', value: cardData.alias || 'Sin alias' },
      { title: 'Número de la tarjeta', value: cardData.maskedPAN || 'Pendiente de firma' },
      { 
        title: 'Tipo', 
        value: cardData.type === 'DEBIT' ? 'Debito Física' : cardData.type === 'CREDIT' ? 'Credito Física' : cardData.type 
      },
      { title: 'Fecha de caducidad', value: this.formattedExpiration },
    ];
  }

  buildLimitsTable(cardData: CardDetail) {
    return [
      { title: 'Límite diario de gasto', value: this.currencyPipe.transform(cardData.limits.purchase.daily, 'EUR', 'symbol', '1.2-2', 'es') },
      { title: 'Límite semanal de gasto', value: this.currencyPipe.transform(cardData.limits.purchase.weekly, 'EUR', 'symbol', '1.2-2', 'es')},
      { title: 'Límite mensual de gasto', value: this.currencyPipe.transform(cardData.limits.purchase.monthly, 'EUR', 'symbol', '1.2-2', 'es') },
      { title: 'Límite diario de retirada', value: this.currencyPipe.transform(cardData.limits.atm.daily, 'EUR', 'symbol', '1.2-2', 'es')},
      { title: 'Límite semanal de retirada', value: this.currencyPipe.transform(cardData.limits.atm.weekly, 'EUR', 'symbol', '1.2-2', 'es') },
      { title: 'Límite mensual de retirada', value: this.currencyPipe.transform(cardData.limits.atm.monthly, 'EUR', 'symbol', '1.2-2', 'es') },
      
    ];
  }
}
