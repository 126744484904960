import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CardDetail } from '../../models/card/cards';
import { Address } from '../../models/user/userData';
import { AccountMovements } from '../../models/accounts/account-movements';
import { FilterParams } from '../../models/filters/filters.model';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient) { }

  activateCard(userId: string, accountId: string, cardId: string, body?: any): Observable<any> {
    return this.http.post<any>(`${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/activate`, body);
  }

  deleteCard(
    userId: string,
    accountId: string,
    cardId: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`
    );
  }

  getPin(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/pin`
    );
  }

  getAllCards(userId: string): Observable<CardDetail[]> {
    return this.http.get<CardDetail[]>(
      `${environment.coreUrl}/${userId}/cards`
    );
  }

  getCard(
    userId: string,
    accountId: string,
    cardId: string,
  ): Observable<CardDetail> {
    return this.http.get<CardDetail>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`
    );
  }

  getCardMovements(userId: string, accountId: string, cardId: string, params?: any): Observable<AccountMovements[]> {
    return this.http.get<AccountMovements[]>(`${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/transactions`, { params });
  }

  public requestContract(accountId: string, userId: string, cardId: string, body: { urlOk: string, urlKo: string }): Observable<any> {
    return this.http.post(`${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/contract/request`, body)
  }

  verifyContract(accountId: string, userId: string, cardId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/contract/verify`,
      null
    );
  }

  public createCompanyCardAndAddress(companyId: string, employeeId: string, accountId: string, alias: string, address: Address) {
    const body = {
      alias: alias,
      address: address
    }
    return this.http.post<any>(`${environment.companyUrl}/company/${companyId}/employee/${employeeId}/account/${accountId}/card/create`, body);
  }

  createCard(userId: string, accountId: string, cardData: any): Observable<any> {
    return this.http.post<any>(`${environment.coreUrl}/${userId}/account/${accountId}/cards`, cardData);
  }

  updateCard(
    userId: string,
    accountId: string,
    cardId: string,
    cardData: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`,
      cardData
    );
  }

  patchCard(
    userId: string,
    accountId: string,
    cardId: string,
    cardData: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`,
      cardData
    );
  }
}
