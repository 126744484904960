import { PeriodType, UserData } from 'src/app/core/models/user/userData';
import { Component, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  

  public isLoading = true;
  public myChart: any;
  public breakpoint: number = 768;
  public baseInnerWidth!: number;
   @Input() userData!: UserData;
  public selectedPeriod: PeriodType = 'week';
  public chartData!: any;
  public hasChartLoaded = false;

  ngOnInit(): void {
  }
  
  constructor(private userService: UserService) {}
  ngOnChanges(): void {
    if (!this.userData || this.hasChartLoaded) return;
    this.baseInnerWidth = window.innerWidth;
    this.hasChartLoaded = true;
    this.fetchChartData(this.selectedPeriod);
  }
  fetchChartData(period: PeriodType) {
    this.userService.getChartData(period).subscribe({
      next: (res: any) => {
        this.chartData = res;
        this.createChart(this.baseInnerWidth, period);
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error al cargar datos del gráfico:', err);
        this.isLoading = false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (((this.baseInnerWidth > this.breakpoint) && (event.target.innerWidth < this.breakpoint)) ||
        ((this.baseInnerWidth < this.breakpoint) && (event.target.innerWidth > this.breakpoint))) {
      this.updateVisibility(event.target.innerWidth);
    }
  }

  updateVisibility(width: number) {
    this.baseInnerWidth = width;
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.createChart(width, this.selectedPeriod);
  }

  setPeriod(period: PeriodType) {
    this.selectedPeriod = period;
    this.fetchChartData(period);
  }

  createChart(width: number, period: string): void {
    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    if (!canvas) {
      console.error("Canvas 'myChart' no encontrado.");
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error("No se pudo obtener el contexto 2D.");
      return;
    }

    if (this.myChart) {
      this.myChart.destroy();
    }

    let labels: string[] = [];
    let ingresos: number[] = [];
    let gastos: number[] = [];

    if (this.chartData) {
      let data = this.chartData;
      if (period === 'week' && data.week.incomesAndExpenses) {
        labels = data.week.incomesAndExpenses.map((item: any) => item.xindex);
        ingresos = data.week.incomesAndExpenses.map((item: any) => item.incomes);
        gastos = data.week.incomesAndExpenses.map((item: any) => item.expenses);
      } else if (period === 'month' && data.month.incomesAndExpenses) {
        labels = data.month.incomesAndExpenses.map((item: any) => item.xindex);
        ingresos = data.month.incomesAndExpenses.map((item: any) => item.incomes);
        gastos = data.month.incomesAndExpenses.map((item: any) => item.expenses);
      } else if (period === 'quarter' && data.quarter.incomesAndExpenses) {
        labels = data.quarter.incomesAndExpenses?.map((item: any) => item.xindex) || [];
        ingresos = data.quarter.incomesAndExpenses?.map((item: any) => item.incomes) || [];
        gastos = data.quarter.incomesAndExpenses?.map((item: any) => item.expenses) || [];
      }
    }

    const backgroundColorPlugin = {
      id: 'customBackgroundColor',
      beforeDraw: (chart: any) => {
        const { ctx, width, height } = chart;
        ctx.save();
        ctx.fillStyle = '#f9f7f1';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    };

    Chart.register(backgroundColorPlugin);
    this.myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Ingresos',
            data: ingresos,
            backgroundColor: '#1BA05D',
            borderRadius: 3,
          },
          {
            label: 'Gastos',
            data: gastos,
            backgroundColor: '#F76E6E',
            borderRadius: 3,
          },
         
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: number | string) {
                const numericValue = typeof value === 'string' ? parseFloat(value) : value;
                return numericValue >= 1000 ? numericValue / 1000 + 'K' : numericValue;
              },
              color: '#94A3B8',
              'backdropPadding': 10,
              font: {
                size: 8,
              },
            },
            grid: {
              drawOnChartArea: true,
              color: '#e9dec9', 
              lineWidth: 0.5,
            },
            border: {
              dash: [5, 5]
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: '#64748B',
              font: {
                size: 10,
              },
            },
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        },
      },
    });
  }
}
