
      <div class="modal-content">
        <div class="modal-body">
          <p>{{modalMessage}}</p>
          <mat-form-field class="input" *ngIf="hasInput">
            <input matInput [(ngModel)]="inputValue">
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="button" class="blue-private-stroked-button" mat-dialog-close><p>Cancelar</p></button>
          <button type="button" class="blue-private-button" (click)="customAction()" [disabled]="hasInput && !inputValue.trim()">{{modalOption}}</button>
        </div>
      </div>

  
 