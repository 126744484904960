export function getDocumentDescription(doc: string) {
  let document = {
    title: '',
    description: '',
    instructions: [''],
  };

  switch (doc) {
    case 'NIF':
      document.title = 'Tarjeta de Identificación Fiscal';
      document.description =
        'Es el documento oficial emitido por la Agencia Tributaria que acredita su número de identificación fiscal. Este número es esencial para la identificación de la empresa en sus obligaciones fiscales y administrativas. ';
      document.instructions = ['1. Subir el documento para su validación'];
      break;
    case 'ESC':
      document.title = 'Escritura de Constitución de la Sociedad';
      document.description =
        'Es el documento notarial que acredita la creación de la empresa y recoge información esencial como su denominación social, objeto, estructura y socios fundadores. Este documento es esencial para verificar la existencia legal de la sociedad.';
        document.instructions = ['1. Subir una copia del documento de la escritura para su validación'];
      break;
    case 'ESA':
      document.title = 'Escritura de Apoderamiento de los Apoderados';
      document.description =
        'Es el documento notarial que otorga poderes a determinadas personas para actuar en nombre de la empresa. Este documento es esencial para acreditar la capacidad de los apoderados para actuar en nombre de la sociedad. ';
        document.instructions = ['1. Subir una copia del documento de la escritura para su validación'];
      break;
    case 'TIR':
      document.title = 'Certificado de Titularidad Real'; 
      document.description =
        'Es un documento que identifica a las personas físicas que poseen o controlan, directa o indirectamente, más del 25% del capital o los derechos de voto de la empresa. Este certificado es esencial para garantizar la transparencia en la estructura de propiedad y cumplir con la normativa de prevención de blanqueo de capitales.';
        document.instructions = ['1. Descargar la plantilla (Plantilla TIR.pdf)', '2. Completar los datos y firmar el documento digitalmente', '3. Subir una copia del certificado para su validación'];
      break;
    case 'RMA':
      document.title = 'Escritura del Órgano Actual de Administración';
      document.description =
        'Es el documento notarial que certifica la composición y estructura del órgano de administración vigente de la empresa. Este documento es esencial para acreditar quiénes están autorizados para actuar en nombre de la sociedad.';
        document.instructions = ['1. Subir una copia del documento de la escritura para su validación'];
      break;
    case 'PEP':
      document.title =
        'Declaración de la Condición de Persona de Responsabilidad Pública';
      document.description =
        'Es el documento que certifica si una persona ocupa, o ha ocupado, un cargo público relevante o una posición de responsabilidad en una empresa estatal. Este documento es fundamental para cumplir con la normativa de prevención de blanqueo de capitales y acreditar la condición de persona expuesta políticamente.';
        document.instructions = ['1. Descargar la plantilla (Plantilla PEP.pdf)', '2. Completar los datos y firmar el documento digitalmente', '3. Subir una copia del certificado para su validación'];
      break;
    case 'CAN':
      document.title = 'Cuentas Anuales de la Empresa o Impuesto de Sociedades';
      document.description =
        'Es el documento que incluye información financiera sobre el desempeño y situación financiera de la empresa durante un período específico y es esencial para verificar la solvencia y el cumplimiento de las obligaciones fiscales de la sociedad.';
        document.instructions = ['1. Subir una copia de las cuentas anuales o del impuesto de sociedades de la sociedad del último ejercicio disponible para su validación'];
      break;
    case 'IDE':
      document.title = 'Documento de Identificación del Administrador';
      document.description = 'Es el documento necesario para verificar su identidad y garantizar el cumplimiento de los requisitos legales y normativos aplicables.';
      document.instructions = ['1. Subir una copia por ambas caras del documento de identidad del administrador para su validación. Puede ser DNI, NIE o Pasaporte según corresponda.'];
      break;
    default:
      document.title = '';
      document.description = '';
      break;
  }

  return document;
}

export enum DOCUMENT_STATE {
  REQUIRED = 'REQUIRED',
  PENDING = 'PENDING',
  PENDING_SEFIDE = 'PENDING-SEFIDE',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export function getDocumentState(state: string) {
  let result = {
    classCss: '',
    viewValue: '',
  };

  switch (state) {
    case DOCUMENT_STATE.REQUIRED:
      result.classCss = 'required';
      result.viewValue = 'Pendiente';
      break;
    case DOCUMENT_STATE.PENDING:
      result.classCss = 'pending';
      result.viewValue = 'En revisión';
      break;
    case DOCUMENT_STATE.PENDING_SEFIDE:
      result.classCss = 'pending-sefide';
      result.viewValue = 'Pte. Sefide';
      break;
    case DOCUMENT_STATE.VERIFIED:
      result.classCss = 'verified';
      result.viewValue = 'Aprobada';
      break;
    case DOCUMENT_STATE.REJECTED:
      result.classCss = 'rejected';
      result.viewValue = 'Rechazada';
      break;
    case DOCUMENT_STATE.EXPIRED:
      result.classCss = 'expired';
      result.viewValue = 'Caducada';
      break;
    default:
      break;
  }
  return result;
}

export const stateEmployee = (state: string) => {
  switch (state.toLowerCase()) {
    case 'registered':
      return 'Registrado';
    case 'document_verified':
      return 'Verificado';
    case 'phone_verified':
      return 'Tlf. Verificado';
    case 'pending_verification':
      return 'Pte. Verificación';
      case 'rejected':
      return 'Rechazado';
    default:
      return state;
  }
};
