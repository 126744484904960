import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { catchError, finalize, of, tap } from 'rxjs';
import { Segment } from 'src/app/core/models/plans/plans';
import { CompanyService } from 'src/app/core/services/userArea/company.service';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  public resetPasswordEmail: FormControl = new FormControl('', [Validators.email, Validators.required]);
  public loader!: boolean;

  public showAlert!: boolean;
  public alertMessage!: string;
  public alertStatus!: string;
  private _cif!: string;

  @Input() userType!: Segment;
  @Input() set cif(value: string) {
    this._cif = value;
  }
  get cif(): string {
    return this._cif;
  }

  constructor(private userService: UserService, private companyService: CompanyService) {}

  resetPassword() {
   
    const body = this.userType === Segment.INDIVIDUAL ? { email: this.resetPasswordEmail.value } : 
        { 
          email: this.resetPasswordEmail.value, 
          cif: this._cif 
        };
  
    this.loader = true;
  
    if(this.userType === Segment.INDIVIDUAL){
      this.userService.resetPassword(body).pipe(
        tap(() => {
          this.alertStatus = "ok"
          this.alertMessage = '¡Perfecto! Por favor, revisa tu correo electrónico'
        }),
        catchError((error) => {
          this.alertStatus = "ko";
          if (error.message === 'not found') {
            this.alertMessage = "No hay ninguna cuenta con el email introducido"
          } else {
            this.alertMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo más tarde"
          }
  
          return of(null)
        }),
        finalize(() => {
          this.loader = false;
          this.showAlert = true;
          this.resetPasswordEmail.reset()
        })
      ).subscribe()
    }else{
      this.companyService.resetPassword(body).pipe(
        tap(() => {
          this.alertStatus = "ok"
          this.alertMessage = '¡Perfecto! Por favor, revisa tu correo electrónico'
        }),
        catchError((error) => {
          this.alertStatus = "ko";
          if (error.message === 'not found') {
            this.alertMessage = "No hay ninguna cuenta con el email y/o CIF introducido"
          } else {
            this.alertMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo más tarde"
          }
  
          return of(null)
        }),
        finalize(() => {
          this.loader = false;
          this.showAlert = true;
          this.resetPasswordEmail.reset()
        })
      ).subscribe()
    }
  }
}
