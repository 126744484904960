import { Component } from '@angular/core';

@Component({
  selector: 'app-help-shared',
  templateUrl: './help-shared.component.html',
  styleUrl: './help-shared.component.scss'
})
export class HelpSharedComponent {

}
