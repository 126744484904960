import { Component } from '@angular/core';
import { catchError, of, tap } from 'rxjs';
import { ExhibitionContactForm } from 'src/app/core/models/contact/contact';
import { ContactService } from 'src/app/core/services/contact/contact.service';
enum FormStatus {
  INIT,
  SENT,
  ERROR
}
@Component({
  selector: 'app-exhibition-stand-landing',
  templateUrl: './exhibition-stand-landing.component.html',
  styleUrl: './exhibition-stand-landing.component.scss'
})
export class ExhibitionStandLandingComponent {
  public status = FormStatus.INIT
  FormStatus = FormStatus

  constructor(private contactService: ContactService) { }

  sendContactForm(contactData: ExhibitionContactForm) {
    this.contactService.createEventsContact(contactData).pipe(
      tap(res => {
        this.status = FormStatus.SENT
      }),
      catchError( error => {
        console.error('Error al enviar el form', error)
        return of (null)
      })
    ).subscribe();
  }
}
