import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Output, EventEmitter, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateRanges } from 'src/app/core/extensions/date-ranges.extensions';
import { containsNumbers, preventLetters, setCurrencyFormat } from 'src/app/core/extensions/functions';
import { DateRange, FilterParams } from 'src/app/core/models/filters/filters.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent {
  public filterForm!: FormGroup;
  public panelOpenState: boolean = false;
  public formattedMinAmount: any;
  public formattedMaxAmount: any;
  public maxDate = new Date();

  public minAmount!: string
  public maxAmount!: string

  public dateRanges!: DateRange[];
  public isDisabled!: boolean;
  private isRangeSelected!: boolean;
  private lasteRangeSelected!: any
  numericInput!: FormControl;
  preventLetters = preventLetters
  containsNumbers = containsNumbers

  @Output() filtersEvent = new EventEmitter<FilterParams>();
  @Input() isCards?: boolean
  @Input() isReceipts?: boolean


  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FilterComponent>,) {
    this.filterForm = this.formBuilder.group({
      startDate: [],
      endDate: [],
      minAmount: [],
      maxAmount: []
    })
    if (data) {
      this.filterForm.patchValue({
        startDate: data.from || '',
        endDate: data.to || '',
        minAmount: data.amountFrom || '',
        maxAmount: data.amountTo || ''
      })
      this.minAmount = data.amountFrom;
      this.maxAmount = data.amountTo
    }
    console.log(this.filterForm.value)
    this.filterForm.valueChanges.subscribe(() => {
      this.isDisabled = this.isFormEmpty();
      if (this.isRangeSelected && this.lasteRangeSelected) {
        const { startDate, endDate } = this.filterForm.value;

        if (
          startDate !== this.lasteRangeSelected.startDate ||
          endDate !== this.lasteRangeSelected.endDate
        ) {
          this.dateRanges.forEach(filter => filter.active = false);
          this.isRangeSelected = false;
          this.lasteRangeSelected = null;
        }
      }
    });

    this.dateRanges = [
      {
        name: 'previousMonth',
        label: 'Mes anterior',
        range: DateRanges.getPreviousMonthRange(),
        active: false,
      },
      {
        name: 'currentMonth',
        label: 'Mes actual',
        range: DateRanges.getCurrentMonthRange(),
        active: false,
      },
      {
        name: 'previousQuarter',
        label: 'Trimestre anterior',
        range: DateRanges.getPreviousQuarterRange(),
        active: false,
      },
      {
        name: 'currentQuarter',
        label: 'Trimestre actual',
        range: DateRanges.getCurrentQuarterRange(),
        active: false,
      }
    ]

  }

  ngOnInit() {
    this.numericInput = this.filterForm.get('minAmount') as FormControl;
    this.isDisabled = this.isFormEmpty()
  }

  isFormEmpty(): boolean {
    console.log(this.filterForm.controls)
    return Object.values(this.filterForm.controls).every(control => control.value === "" || control.value === null);
  }

  onSubmit() {
    const dataFromForm = {
      startDate: this.datePipe.transform(this.filterForm.get('startDate')?.value, 'YYYY-MM-dd'),
      endDate: this.datePipe.transform(this.filterForm.get('endDate')?.value, 'YYYY-MM-dd'),
      minAmount: this.minAmount,
      maxAmount: this.maxAmount
    }

    const filtersToSend: FilterParams = {}
    dataFromForm.startDate ? filtersToSend.from = dataFromForm.startDate : null;
    dataFromForm.endDate ? filtersToSend.to = dataFromForm.endDate : null;
    dataFromForm.minAmount ? filtersToSend.amountFrom = dataFromForm.minAmount : null;
    dataFromForm.maxAmount ? filtersToSend.amountTo = dataFromForm.maxAmount : null;
    /* this.filtersEvent.emit(filtersToSend) */
    this.dialogRef.close(filtersToSend)
  }



  private cleanAmountInput(controlName: string): string {
    const rawValue = this.filterForm.get(controlName)?.value;
    const withoutDots = rawValue.replace(/[.]/g, '');
    const withDotDecimal = withoutDots.replace(/[,]/g, '.');
    const sanitized = withDotDecimal.replace(/\.$/, ''); // elimina punto al final
    const rounded = Math.round(parseFloat(sanitized) * 100) / 100 || '';
    return rounded.toString();
  }

  formatAmount(type: 'min' | 'max') {
    const controlName = type === 'min' ? 'minAmount' : 'maxAmount';
    const cleanedAmount = this.cleanAmountInput(controlName);
    if (type === 'min') {
      this.minAmount = cleanedAmount;
      this.formattedMinAmount = setCurrencyFormat(cleanedAmount);
    } else {
      this.maxAmount = cleanedAmount;
      this.formattedMaxAmount = setCurrencyFormat(cleanedAmount);
    }
  }

  resetAll() {
    this.filterForm.reset()
    this.onSubmit()
  }

  dateRange(range: DateRange) {
    this.isRangeSelected = true;
    this.lasteRangeSelected = { startDate: DateRanges.formatDate(range.range.from), endDate: DateRanges.formatDate(range.range.to) };

    this.dateRanges.forEach(item => {
      item.active = (item === range);
    });
    this.filterForm.patchValue({
      startDate: DateRanges.formatDate(range.range.from),
      endDate: DateRanges.formatDate(range.range.to),
    })

  }
}
