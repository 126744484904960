<div class="navBar">
  <div [ngClass]="{
        'navBar_container':!isBurgerMenu,
        'burger_container': isBurgerMenu
      }">
    <!-- NORMAL MENU -->
    <div *ngFor="let item of _linkList.links">
      <div class="dropdown" *ngIf="!isBurgerMenu" routerLinkActive="active-mat">
        <p [ngClass]="_linkList.isLoggedIn ? 'private-list-item' : 'public-list-item'" *ngIf="item.deploy">{{item.title}} <mat-icon>expand_more</mat-icon></p>
        <div class="dropdown-content" *ngIf="item.deploy">
          <ng-container *ngFor="let subItem of item.subtitles" >
             <span *ngIf="!isProductionEnvironment || (isProductionEnvironment && !subItem.hideInProduction)" class="link" routerLink="{{subItem.url}}" routerLinkActive="active-sublink" >          
            <p style="margin: 0;"><img *ngIf="subItem.icon" src="{{subItem.icon}}" alt="">{{subItem.title}}</p>
          </span>
          </ng-container>
        </div>
        <p style="margin: 0;" [ngClass]="_linkList.isLoggedIn ? 'private-list-item' : 'public-list-item'" *ngIf="!item.deploy" routerLink="{{item.url}}" [queryParams]="item.queryParam ? {section: item.queryParam} : null" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><span><mat-icon
              *ngIf="isBurgerMenu">chevron_right</mat-icon></span>{{item.title}}</p>
        <mat-divider *ngIf="isBurgerMenu"></mat-divider>
      </div>
    </div>

    <!-- BURGER MENU -->

    <div *ngIf="isBurgerMenu" class="accordion">
        <div class="link_item" *ngFor="let item of _linkList.links">
          <div *ngIf="item.deploy">
            <p class="link_title" (click)="toggleConfig(item)">
              {{ item.title }}  <mat-icon>{{ item.linkDeployed ? 'expand_more' : 'chevron_right' }}</mat-icon>
            </p>
            <div class="links_deployed"  *ngIf="item.linkDeployed" [@expandOnEnter] [@collapseOnLeave]>
              <ng-container *ngFor="let subItem of item.subtitles" >
              <div class="link_deployed" *ngIf="!isProductionEnvironment || (isProductionEnvironment && !subItem.hideInProduction)">
                <p class="link_title" routerLink="{{subItem.url}}" routerLinkActive="active-burger"  (click)="closeMenu(); toggleConfig(item)" >{{subItem.title}}</p>
              </div>
              </ng-container>
            </div>
          </div>
            <p class="link_title" routerLink="{{item.url}}" [queryParams]="item.queryParam ? {section: item.queryParam} : null" routerLinkActive="active-burger" [routerLinkActiveOptions]="{exact:true}"  *ngIf="!item.deploy" (click)="closeMenu()">{{item.title}}</p>
        </div>
    </div>
  </div>
</div>