import { catchError, map, of, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, Input, HostListener, ViewChild, EventEmitter, Output } from '@angular/core';
import { AccountMovements } from 'src/app/core/models/accounts/account-movements';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { downloadFile } from '../../../core/extensions/download-files';
import { getMovementType } from 'src/app/core/extensions/movement.extension';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { CardService } from 'src/app/core/services/card/card.service';

@Component({
  selector: 'app-movements-table',
  templateUrl: './movements-table.component.html',
  styleUrls: ['./movements-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MovementsTableComponent {
  public movementsData!: any[];
  public displayedColumns: string[] = ['accountingDate', 'type', 'concept', 'amount'];
  public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  public expandedElement!: any;
  public movementDetail!: any;
  public hideConcept!: boolean;
  public fileMovement: any;
  /* public offset: number = 20 */
  public sticky: any[] = [];

  @Input() offset?: number = 20
  @Input() loader!: boolean;
  @Input() dataSource!: any;
  @Input() selectedAccountId?: string
  @Input() hideDetail?: boolean

  getMovementType = getMovementType;

  constructor(private userService: UserService, private downloadReceipt: UserService, private cardService: CardService) {
  }

  ngOnInit() {
    this.setColumns();
  }

  setLoading() {
    this.loader = true;
  }

  setColumns() {
    !this.hideDetail ? this.columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'] : this.columnsToDisplayWithExpand = this.displayedColumns;
    
  }

  /* getCardMovements(userId: string, accountId: string, cardId: string) {
    this.cardService.getCardMovements(userId, accountId, cardId).subscribe(movements => {
      this.movementsData = movements;
         this.dataSource = new MatTableDataSource(this.movementsData);
      this.loader = false;
    });
  } */

  toggleExpandedRow(element: any): void {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.movementDetail = null
      this.expandedElement = element;
      this.getMovementDetail(this.expandedElement)
    }
  }

  getMovementDetail(movement: AccountMovements) {
      this.movementDetail = movement;
      console.log(this.movementDetail);
    
  }

  downloadMovement(movement: AccountMovements) {
    this.downloadReceipt.downloadMovement(sessionStorage.getItem('userId')!, this.selectedAccountId!, movement.id, movement.operationNumber).subscribe(res => {
      this.fileMovement = res
      downloadFile(this.fileMovement, 'movimiento');
    })
  }
}
