import { Component, HostListener, InjectionToken, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { HeaderLinksModel, NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { Segment } from 'src/app/core/models/plans/plans';
import { AuthService } from 'src/app/core/services';
import { CompanyService } from 'src/app/core/services/userArea/company.service';
import { UserRoleService } from 'src/app/core/services/userArea/user-role.service';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-header-private',
  templateUrl: './header-private.component.html',
  styleUrls: ['./header-private.component.scss']
})
export class HeaderPrivateComponent implements OnInit {
  public role!: string;
  @Input() imageData!: string;
  @Input() linkListPrivate!: HeaderLinksModel;
  public userName!: string;
  public prueba: any;
  public initials!: string;
  isDropdownOpen = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userRoleService: UserRoleService
  ) { }
  ngOnInit(): void {
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL ? this.role = 'particulares' :
      sessionStorage.getItem('role')! == Segment.BUSINESS ? this.role = 'empresas' : null;
    this.getAvatar()
  }

  capitalizeWords(value: string): string {
    if (!value) return value;
    return value
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  toggleDropdown(event: Event): void {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event): void {
    const targetElement = event.target as HTMLElement | null;
    if (targetElement && !targetElement.closest('.dropdown')) {
      this.isDropdownOpen = false;
    }
  }

  getInitials(name: string): string {
    const words = name.trim().split(/\s+/);
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase();
    }
    if (words.length === 2) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
    }
    return words[0].charAt(0).toUpperCase() +
      words[1].charAt(0).toUpperCase() +
      words[2].charAt(0).toUpperCase();
  }

  getAvatar() {
    this.userRoleService.getStateForRole()?.getProfile().pipe(
      tap((res) => {
        this.userName = res.name;
        if (res.avatar) {
          this.imageData = `data:image/jpeg;base64,${res.avatar}`;
        } else {
          this.initials = this.getInitials(res.name);
        }
      })
    ).subscribe();
  }

  logout() {
    this.auth.signOut()
    this.router.navigate(['/'])
  }

}
export const MY_TOKEN = new InjectionToken<string>('my-token')