import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Segment } from 'src/app/core/models/plans/plans';
import { ManagerModalComponent } from '../manager-modal/manager-modal.component';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { PhoneValidationComponent } from '../phone-validation/phone-validation.component';
import { environment } from 'src/environments/environment';
import { downloadFile } from 'src/app/core/extensions/download-files';
import { AccountsList, CardsList } from 'src/app/core/models/user/userData';
import { HttpClient } from '@angular/common/http';
import { DetailComponent } from 'src/app/modules/accounts/detail/detail.component';
import { CardDetail } from 'src/app/core/models/card/cards';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SpinnerComponent } from '../../layout/spinner/spinner.component';
import { tap, catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { CardService } from 'src/app/core/services/card/card.service';
import { AlertModel } from 'src/app/core/models/documents/alerts';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent implements OnInit {
  public role!: string;
  public openModalData: boolean = false;
  public fileCertificate: any;
  public scaCode!: string;
  public userId!: string
  public showAlert: boolean = false;
  public alertMessage!: string;
  public alertStatus!: string;
  private dialogRef: any;
  private accountId!: string
  private cardId!: string
  public pin!: string;

  @Input() accountData!: AccountsList;
  @Input() cardData!: CardDetail;
  @Output() alertsEmit = new EventEmitter<AlertModel>();
  constructor(private dialog: MatDialog, private userDataService: UserService, private http: HttpClient, private activate: CardService,) { }

  ngOnInit(): void {
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL ? this.role = 'particulares' :
      sessionStorage.getItem('role')! == Segment.BUSINESS ? this.role = 'empresas' : null;
    this.userId = sessionStorage.getItem('userId')!;
    if(this.cardData) {
      this.accountId = this.cardData.account.id
      this.cardId = this.cardData.id
    }
  }
  @Input() loader?: boolean;

  openInfo() {
    this.openModalData = true;
  }
  public openModal() {
    this.dialog.open(ManagerModalComponent, {
      disableClose: true,
    });
  }

  openCardDetailCard() {
    this.dialog.open(DetailComponent, { data: { cardData: this.cardData } });
  }

  openLimitsDetailCard() {
    this.dialog.open(DetailComponent, { data: { cardData: this.cardData, isLimits: true } });
  }

  openDialog() {
    this.dialog.open(DetailComponent, { data: { accountData: this.accountData } });

  }

   openConfirmationModal(account: AccountsList) {
      this.dialog.open(ConfirmationModalComponent, {
        disableClose: true,
        data: {
          modalMessage:
            'Te vamos a redirigir a una página externa para firmar el contrato. ¿Deseas continuar?',
          modalOption: 'Ir a la firma',
          action: () => {
            this.getSignatureUrl(account);
          },
        },
      });
    }

    showPIN() {
      this.activate.getPin(this.userId, this.accountId, this.cardId).subscribe(
        {
          next: (response: any) => {
            this.pin = response;
            window.open(this.pin, '_blank');
            this.showAlert = true;
            this.alertStatus = 'ok';
            this.alertMessage = "Su código PIN está disponible en la nueva pestaña que se ha abierto."
          },
          error: () => {
            this.showAlert = true;
            this.alertStatus = 'ko';
            this.alertMessage = "Error al recuperar el PIN"
          }
        }
      )
    }

    sendActivationSms() {
        this.showAlert = false;
    
        this.activate.activateCard(this.userId, this.accountId, this.cardId)
          .subscribe({
            next: (response) => {
              this.showAlert = true;
              this.alertStatus = 'ok';
              this.alertMessage = "Tarjeta activada con éxito";
            },
            error: (error) => {
              if (error.code === 428 || error.api_code === "ES06008") {
               this.dialogRef = this.dialog.open(ConfirmModalComponent, {
                  disableClose: true,
                  data: {
                    closeFromParent: true,
                    message: 'Introduce el SMS que te hemos enviado',
                    cancel: 'Cancelar',
                    confirm: 'Activar tarjeta',
                    inputRequired: true,
                    actionFunction: (sca: string) => {
                      this.activateCard(sca)
                    },
                  }
                })
              } else {
                this.showAlert = true;
                this.alertStatus = 'ko';
                this.alertMessage = "No se pudo enviar el SMS.";
              }
            }
          });
      }

      activateCard(sca: string) {
        const body = {
          sca: sca
        }
        this.activate.activateCard(this.userId, this.accountId, this.cardId, body)
        .subscribe({
          next: () => {
            this.showAlert = true;
            this.alertStatus = 'ok';
            this.alertMessage = "Tarjeta activada con éxito";
          },
          error: (error: { api_code: string; }) => {
            if(error.api_code == 'ES06010'){
              this.dialogRef.componentInstance.updateMessage('El código introducido no es válido')
            } else  if(error.api_code == 'ES06023'){
              this.dialogRef.close()
              this.showAlert = true;
              this.alertStatus = 'ko';
              this.alertMessage = "Has realizado demasiados intentos fallidos. Por favor, inténtalo de nuevo en 5 minutos";
            }  else {
              this.dialogRef.close()
              this.showAlert = true;
              this.alertStatus = 'ko';
              this.alertMessage = "No se pudo activar la tarjeta.";
            }
            }
        });
      }

      openSignContract(card: any) {
        let dialogRef = this.dialog.open(SpinnerComponent, {
          disableClose: true,
          data: {
            dynamic: true,  
            messages: [
              'Estamos generando tu contrato',
              'Esto puede tardar unos segundos',
              'Un momento, por favor',
            ],
          },
        });
        this.accountId = card.account.id
        this.showAlert = false;
        const autoLogin = sessionStorage.getItem("token")!;
        const returnUrls = {
          urlOk: `${environment.environmentUrl}/onboarding/contratos?signedCard=ok&role=${this.role}&token=${autoLogin}&cardId=${card.id}`,
          urlKo: `${environment.environmentUrl}/onboarding/contratos?signedCard=ko&role=${this.role}&token=${autoLogin}&cardId=${card.id}`
        }
        this.activate.requestContract(this.accountId, this.userId, card.id, returnUrls).pipe(
          tap((res) => {
            this.dialog.open(ConfirmationModalComponent, {
              disableClose: true,
              data: {
                modalMessage: 'Te vamos a redirigir a una página externa para firmar el contrato',
                modalOption: 'Ir a la firma',
                action: () => {
                  this.dialog.closeAll()
                  this.navigateToSignature(res.url)
                }
              }
            });
          }),
          catchError(error => {
            this.alertsEmit.emit({status: 'ko', message: "Ha ocurrido un error al generar tu contrato. Por favor, inténtalo mas tarde."})
            return of(null)
          })
        ).subscribe( fin => dialogRef.close())
      }
      navigateToSignature(url: string) {
        sessionStorage.clear()
        window.location.href = url
      }
    
      setAlert(status: string, message: string) {
        this.alertMessage = message;
        this.alertStatus = status;
        this.showAlert = true;
      }

    getSignatureUrl(account: any) {
        this.dialog.open(SpinnerComponent, {
          disableClose: true,
          data: {
            dynamic: true,  
            messages: [
              'Estamos generando tu contrato',
              'Esto puede tardar unos segundos',
              'Un momento, por favor',
            ],
          },
        });
        const autoLogin = sessionStorage.getItem('token')!;
        const returnUrls = {
          urlOk: `${environment.environmentUrl}/onboarding/contratos?contractId=${account.id}&signedContract=ok&role=${this.role}&token=${autoLogin}`,
          urlKo: `${environment.environmentUrl}/onboarding/contratos?contractId=${account.id}&signedContract=ko&role=${this.role}&token=${autoLogin}`,
        };
    
        this.userDataService
          .signNewContract(
            sessionStorage.getItem('userId')!,
            account.id,
            returnUrls
          )
          .pipe(
            tap((res) => {
              sessionStorage.clear();
              this.dialog.closeAll();
              window.location.href = res.url;
            }),
            catchError((error) => {
              console.error('Error signing contract:', error);
              this.dialog.closeAll();
              return of(null);
            })
          )
          .subscribe();
      }

  downloadOwnershipCertificate() {
    this.userDataService.getOwnershipCertificate(this.userId, this.accountData.id).subscribe(res => {
      this.fileCertificate = res
      downloadFile(this.fileCertificate, 'certificadoTitularidad')
    })
  }
  

  downloadBalanceCertificate() {
    this.userDataService.getBalanceCertificate(this.userId, this.accountData.id).subscribe({
      next: (value) => {

      },
      error: (err) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          sca: err.sca,
        }
        if (err.code === 428 && err.sca) {
          this.openPhoneValidationDownload(err.sca);
        }
      }
    })
  }

  openPhoneValidationDownload(sca: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      sca: sca,
      reqActions: () => this.downloadBalanceCertificate()
    };
  
    const dialogRef = this.dialog.open(PhoneValidationComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.scaCode = data;
        const params = { sca: this.scaCode };
  
        this.http.get(`${environment.coreUrl}/${data.endpoint}`, {
          responseType: 'blob'
        }).subscribe({
          next: (value) => {
            this.fileCertificate = value;
            downloadFile(this.fileCertificate, `certificado_balance`);
          },
        });
      }
    });
  }
  
}
