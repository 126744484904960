import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CardService } from 'src/app/core/services/card/card.service';

@Component({
  selector: 'app-card-cancel',
  templateUrl: './card-cancel.component.html',
  styleUrl: './card-cancel.component.scss'
})
export class CardCancelComponent {

public dataCard: any;
public userId!: string;
public showAlert: boolean = false;
public alertMessage!: string;
public alertStatus!: string;

constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<CardCancelComponent>, private router: Router , private deleteService: CardService){
this.dataCard = data
}

ngOnInit(){
  this.userId = sessionStorage.getItem('userId')!
}

confirmDelete() {
  this.showAlert = false;
  this.deleteService.deleteCard(this.userId, this.dataCard.cardData.account.id, this.dataCard.cardData.id)
    .subscribe({
      next: (response) => {
        this.dialog.closeAll();
        this.router.navigate(['/particulares/tarjetas']); 
      },
      error: (error) => {
        console.error(error); 
        this.showAlert = true;
        this.alertStatus = 'ko';
        this.alertMessage = "No se pudo cancelar la tarjeta.";
      }
    });
}

  closeModal(){
    this.dialog.closeAll()
  }

}
