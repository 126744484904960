<div class="content-wrapper">
    <div class="logo-container">
        <div class="logo-img">
            <img src="../../../../assets/images/logos/denarius.svg" alt="">
        </div>
    </div>
    <div class="greeting-container">
        <p class="greeting-tittle"> GRACIAS POR VISITAR<br>
            NUESTRO STAND EN</p>
        <div class="greeting-img">
            <img src="../../../../assets/images/logos/infarma2025.png" alt="">
        </div>
    </div>
    @if (status === FormStatus.INIT) {
    <app-exhibition-form (contactData)="sendContactForm($event)"></app-exhibition-form>
    } @else if (status == FormStatus.SENT) {
    <app-form-sent></app-form-sent>
    }
</div>
