import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { init } from 'aos';
import { catchError, of, tap } from 'rxjs';
import { DocumentsModel } from 'src/app/core/models/documents/documents';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { ShareHoldersService } from 'src/app/core/services/shareholders/share-holders.service';
import { UserRoleService } from 'src/app/core/services/userArea/user-role.service';
enum UploadState {
  INIT,
  FILE_SELECTED,
  UPLOADING,
  SUCCESS,
  ERROR
}
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnChanges {

  //? buttonText: texto del primer botón (Seleccionar, Cambiar archivo); 
  //? buttonSend: texto del segundo botón (subir, subiendo, error...)
  buttonText: string = $localize`:@@upload.files.pick.image:Seleccionar Imagen`
  buttonSend: string = $localize`:@@upload.files.upload.image:Subir`

  public uploadState!: UploadState;

  public selectedFile?: any;
  public nameFile?: string;
  public elementId!: string
  public preview!: string;
  public previewIsImage!: boolean;

  public isCompanyIDE!:boolean;
  public selectedFileRev?: any;
  public nameFileRev?: string;
  public elementIdRev!: string;
  public previewRev!: string;
  public previewRevIsImage!: boolean;


  @Input() fileType!: string;
  @Input() userId?: string;
  @Input() isForm?: boolean;
  @Input() isDocumentsComponent?: boolean;
  @Input() shareHolder?: boolean;
  @Input() hiddenUploadButton?: boolean;

  @Input() documentData?: DocumentsModel //En caso de company, para saber (si procede) los datos del socio

  @Output() fileSelected: EventEmitter<boolean> = new EventEmitter();   // emite cuando se ha seeccionado un archivo (no subido)
  @Output() fileUploadedOk: EventEmitter<boolean> = new EventEmitter(); // Para emitir si se ha subito correctamente
  @Output() closeAlert: EventEmitter<boolean> = new EventEmitter();     // para cerrar el alert en el componente de shareholder cuando subes mas de un archivo y esta el alert abierto.

  // Outputs para formularios:Si se sube, se le pasa el id al padre y sino se emite el error.
  @Output() idFileUploaded: EventEmitter<string> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  UploadState = UploadState
  constructor(
    private sanitizer: DomSanitizer,
    private userRoleService: UserRoleService,
    private contactService: ContactService,
    private shareHolderService: ShareHoldersService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.documentData) {
      if (this.documentData.partnerId) {
        this.elementId = `${this.documentData.type}-${this.documentData.partnerId}`
        this.documentData.type === 'IDE' ? this.isCompanyIDE = true : null;
      } else {
        this.elementId = `${this.documentData.type}`
      }

    } else {
      this.elementId = 'element'
    }

    if(this.isForm){
      this.buttonText = 'Adjuntar archivo'
    }
  }

  ngOnInit(): void {
    this.uploadState = UploadState.INIT
  }


  onFileSelected(event: any, rev?:string) {
    if (rev) {
      this.selectedFileRev = event.target.files[0];
      this.nameFileRev = this.selectedFileRev.name;
      this.convertFileBase64(this.selectedFileRev).then((img: any) => {
        this.previewRev = img.base
        this.previewRev.includes('image') ? this.previewRevIsImage = true : this.previewRevIsImage = false
      })
      this.uploadState = UploadState.FILE_SELECTED
      this.buttonText = $localize`:@@upload.files.change.file:Cambiar`
      this.buttonSend = $localize`:@@upload.files.upload.image:Subir`
    } else {
      this.selectedFile = event.target.files[0];
      this.nameFile = this.selectedFile.name;
      this.convertFileBase64(this.selectedFile).then((img: any) => {
        this.preview = img.base
        this.preview.includes('image') ? this.previewIsImage = true : this.previewIsImage = false
      })
      this.uploadState = UploadState.FILE_SELECTED
      this.buttonText = $localize`:@@upload.files.change.file:Cambiar`
      this.buttonSend = $localize`:@@upload.files.upload.image:Subir`
      /*     this.buttonTextSecondary = $localize`:@@upload.files.change.file:Cambiar` */
      this.fileSelected.emit(true)
    }
  }


  onSubmit() {
    let fileToUpload
    const formData: FormData = new FormData();
    this.uploadState = UploadState.UPLOADING
    this.buttonSend = $localize`:@@upload.files.uploading.image:SUBIENDO`

    if (this.shareHolder) {
      formData.append('file', this.selectedFile);
      // llamada en caso de ser un accionista.
      this.onSubmitShareholders(formData)
    } else {
      if (this.documentData && (this.documentData!.type == 'TIR' || this.documentData!.type == 'IDE')) {
        formData.append('data', JSON.stringify({ type: this.documentData!.type, partnerId: this.documentData!.partnerId }));
        formData.append('files', this.selectedFile);
        if(this.documentData && this.documentData.type === 'IDE' && this.documentData.partnerId){
          formData.append('files', this.selectedFileRev);
        }
      } else {
        formData.append('data', JSON.stringify({ type: this.documentData!.type }));
        formData.append('files', this.selectedFile);
      }
      // llamada para subir archivos de usuarios segun su rol
      this.userRoleService.getStateForRole()?.uploadDocument(sessionStorage.getItem('userId')!, formData).pipe(
        tap(() => {
          this.buttonSend = $localize`:@@upload.files.uploaded.image:SUBIDO CORRECTAMENTE`;
          this.uploadState = UploadState.SUCCESS
          this.checkFiles()

        }),
        catchError((error) => {
          this.buttonSend = 'Error!';
          this.buttonText = 'Subir de nuevo'
          this.uploadState = UploadState.ERROR
          return of(null);
        })
      ).subscribe()
    }

  }

  checkFiles() {
    this.fileUploadedOk.emit(true)
  }


  onSubmitShareholders(formData: FormData) {
    this.shareHolderService.uploadMinutes(formData).pipe(
      tap(() => {
        this.buttonSend = $localize`:@@upload.files.uploaded.image:SUBIDO CORRECTAMENTE`;
        this.uploadState = UploadState.SUCCESS
        this.checkFiles();
        /*  this.fileUploadedOk.emit(true); */

      }),
      catchError((error) => {
        if (error.code === 400) {
          this.buttonSend = 'Error!';
          this.uploadState = UploadState.ERROR
        }
        return of(null);
      })
    ).subscribe()
  }

  onSubmitForContactForms() {

    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile);

    this.contactService.uploadFile(formData).pipe(
      tap((res) => {
        this.idFileUploaded.emit(JSON.stringify(res));
      }),
      catchError((error) => {
        if (error.code === 400) {
          this.uploadState = UploadState.ERROR
          this.buttonSend = 'Error!';
        }
        this.error.emit(true)
        return of(null);
      })
    ).subscribe()
  }

  //funcion para detectar cuando se hace click en el boton de subir archivo.
  closeParentAlert() {
    this.closeAlert.emit(true)
  }

  //llevar a base 64 para obtener la codificación de la imagen y poder realizar la previsualización
  convertFileBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      null
    }
  })


  resetForm() {
    this.uploadState = UploadState.INIT;
    this.buttonText = $localize`:@@upload.files.pick.file:Seleccionar Archivo`
  }

}
