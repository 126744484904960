<div class="all-searchs" [ngClass]="{ 'receipts' : isReceipts, 'cards': isCards}">
  <div class="dropdown">
  <div  class="expansion-body">
      <form [formGroup]="filterForm">
        <div class="filter-box">
          <h2>Filtrar Movimientos <mat-icon mat-dialog-close>close</mat-icon></h2>
          <div class="amount_container">
            <h3>Por importe</h3>
            <div class="inputs">
              <div class="input">
                <p>Desde</p>
                <input formControlName="minAmount" placeholder="0" (keypress)="preventLetters($event)" [value]="formattedMinAmount" (change)="formatAmount('min')"/>
              </div>
              <div class="input">
                <p>Hasta</p>
                <input formControlName="maxAmount" placeholder="0" (keypress)="preventLetters($event)" value="{{ formattedMaxAmount }}"  (change)="formatAmount('max')"/>
              </div>
            </div>
          </div>
          <div class="period_container">
            <h3>Por fecha</h3>
            <div class="inputs">
              <mat-form-field appearance="outline" color="primary">
                <mat-label i18n="@@filter.from">Desde</mat-label>
                <input formControlName="startDate" matInput [matDatepicker]="pickerStart" [max]="maxDate"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" color="primary">
                <mat-label i18n="@@filter.to">Hasta</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="pickerEnd" [max]="maxDate"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="shortcuts">
              @for (item of dateRanges; track $index) {
                <p [class.active]="item.active" class="shortcut" (click)="dateRange(item)">{{item.label}}</p>
              }
            </div>
            <div class="button-filter">
              <button class="blue-private-stroked-button" mat-dialog-close>
                <p>Cancelar</p>
              </button>
              <button class="blue-private-button" [disabled]="isDisabled" (click)="onSubmit()">
                <p>Aplicar filtros</p>
              </button>
            </div>
          </div>
        </div>
      </form>
  </div>
</div>
</div>
