<form [formGroup]="loginForm">
  <div *ngIf="formStep == 'firstStep'">
    <div class="login_container">
      <mat-dialog-actions class="closeDialog">
        <img src="../../../../assets/images/logos/icono_denarius.png" alt="" />
        <button mat-dialog-close class="back-button">
          <mat-icon style="font-size: 20px"> close </mat-icon>
        </button>
      </mat-dialog-actions>
      <div class="loginPad_content">
        <div class="title-container">
          <h2>Accede a tu área</h2>
          <h1>personal</h1>
        </div>
        <div class="loginPad_input">
          <input placeholder="Introduce DNI o NIE" class="inputDNI" type="text" name="dni"
            id="dni" formControlName="dni" maxlength="9" i18n-placeholder="@@dni.nie.login"
            oninput="this.value = this.value.toUpperCase()" />
          <button [disabled]="!loginForm.get('dni')?.valid"
          class="gold-color-button" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>

  <div class="loginForm_background" *ngIf="formStep == 'secondStep'">
    <div class="login_container">
      <mat-dialog-actions class="closeDialog">
        <img src="../../../../assets/images/logos/icono_denarius.png" alt="" />
        <button class="back-button" mat-dialog-close>
          <mat-icon style="font-size: 20px"> close </mat-icon>
        </button>
      </mat-dialog-actions>
      <div>
      <div class="fieldset-pad">
        <h2 i18n="@@login.security.key">Introduce tu clave de seguridad</h2>
        <fieldset [disabled]="true">
          <input
            formControlName="password"
            type="password"
            class="display"
            id="password"
            value="{{ password }}"
            maxlength="6"
          />
        </fieldset>
        <div class="errorMessage">
          <p *ngIf="passError" class="errorPass">
            Nombre de usuario o contraseña no válidos
          </p>
        </div>
      </div>
      <div class="loginPad_container">
        <div class="pad-number">
          <div class="numbers-all">
            <div class="numbers" *ngFor="let item of newArray">
              <button
                mat-ripple
                [disabled]="password.length == 6"
                class="loginPad_number"
                (click)="numClick(item); addValue()"
              >
                {{ item }}
              </button>
            </div>
            <button
              [disabled]="!loginForm.get('password')?.value"
              class="loginPad_number-delete"
              (click)="deleteDisplay()"
            >
            <mat-icon>remove</mat-icon>
            </button>
            <button
              [disabled]="!loginForm.valid"
              class="loginPad_number-check"
              (click)="submit()"
            >
            <mat-icon>check</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
      <div class="backDialog">
        <button class="back-button" (click)="backStep()">
          <mat-icon style="font-size: 15px"> arrow_back_ios </mat-icon>
        </button>
        <p
          class="forgotPass"
          i18n="@@login.remember.password"
          (click)="forgotPassword()"
        >
          No recuerdo mi clave de acceso
        </p>
      </div>
    </div>
  </div>
  <div class="loginForm_background" *ngIf="formStep == 'resetPassword'">
    <div class="login_container">
      <mat-dialog-actions class="closeDialog">
        <img src="../../../../assets/images/logos/icono_denarius.png" alt="">
        <button mat-icon-button mat-dialog-close> <mat-icon style="font-size: 20px"> close </mat-icon></button>
      </mat-dialog-actions>
      <div class="loginPad_content">
        <div class="title-container">
          <h2>Recupera tu</h2>
          <h1>Contraseña</h1>
        </div>
        <div style="width: 100%;">
           <app-reset-password [userType]="Segment.INDIVIDUAL"></app-reset-password>
        <mat-dialog-actions class="backDialog">
          <button mat-icon-button (click)="backStep()"> <mat-icon style="font-size: 15px"> arrow_back_ios
            </mat-icon></button>
        </mat-dialog-actions>
        </div>
       
      </div>
    </div>
  </div>
</form>