import { DatePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FilterParams } from 'src/app/core/models/filters/filters.model';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrl: './filter-tags.component.scss'
})
export class FilterTagsComponent {
@Input() activeFilters?: any;
@Output() untaggedFilters = new EventEmitter<FilterParams>();
visibleFilters: { value: string | number; key: string }[] = [];
private datePipe = inject(DatePipe)
ngOnChanges(): void {
  this.visibleFilters = [];

  if (this.activeFilters?.amountFrom && this.activeFilters?.amountTo) {
    this.visibleFilters.push({
      value: `${this.activeFilters.amountFrom}€ - ${this.activeFilters.amountTo}€`,
      key: 'amountAll',
    });
  } else {
    if (this.activeFilters?.amountFrom) {
      this.visibleFilters.push({
        value: `Desde ${this.activeFilters.amountFrom}€`,
        key: 'amountFrom',
      });
    }
    if (this.activeFilters?.amountTo) {
      this.visibleFilters.push({
        value: `Hasta ${this.activeFilters.amountTo}€`,
        key: 'amountTo',
      });
    }
  }
  if (this.activeFilters?.from && this.activeFilters?.to) {
    this.visibleFilters.push({
      value: `${this.datePipe.transform(this.activeFilters.from, 'dd/MM/YYYY') } - ${this.datePipe.transform(this.activeFilters.to, 'dd/MM/YYYY')}`,
      key: 'dateAll',
    });
  } else  {
    if (this.activeFilters?.from) {
    this.visibleFilters.push({
      value: `Desde ${this.datePipe.transform(this.activeFilters.from, 'dd/MM/YYYY')}` ,
      key: 'from',
    });
  }

  if (this.activeFilters?.to) {
    this.visibleFilters.push({
      value: `Hasta ${this.datePipe.transform(this.activeFilters.to, 'dd/MM/YYYY')}` ,
      key: 'to',
    });
  }
  } 
}

removeFilter(key: any) {
  let newFilters = this.activeFilters;

  delete newFilters[key]
  
  if(key === 'amountAll') { 
    delete newFilters.amountFrom;
    delete newFilters.amountTo
  }
  if(key === 'dateAll') { 
    delete newFilters?.from;
    delete newFilters?.to;
  }

  this.untaggedFilters.emit(newFilters)
  this.ngOnChanges()
}


}
