<div class="header_container">
  <div class="upper-container">
    <div class="logo_container">
      <img src="../../../../assets/images/logos/logo.svg" alt="" routerLink="/">
    </div>
    <app-nav-bar [linkList]="linkListPrivate"></app-nav-bar>
    <div class="elements">
      <button class="blue-private-button" routerLink="/{{role}}/transferencias">
        <p>Nueva transferencia</p>
        <img src="../../../../assets/images/icons/arrow-2.svg" alt="">
      </button>
      <div class="dropdown">
        @if(imageData){
        <img class="icon" [src]="imageData" alt="profile_img">
        } @else {
        <div class="initials-avatar">
          <p>{{ initials }}</p>
        </div>
        }
        <img class="deployable" src="../../../../assets/images/icons/deployable.png" alt="">
        <div class="dropdown-content">
          <a routerLink="/{{role}}/area-personal" routerLinkActive="active-mat">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="vuesax/linear/user-octagon">
                <g id="user-octagon">
                  <path id="Vector"
                    d="M17.5665 7.15003V12.85C17.5665 13.7833 17.0665 14.65 16.2581 15.125L11.3081 17.9834C10.4998 18.45 9.49981 18.45 8.68314 17.9834L3.73314 15.125C2.9248 14.6584 2.4248 13.7917 2.4248 12.85V7.15003C2.4248 6.21669 2.9248 5.34999 3.73314 4.87499L8.68314 2.01666C9.49147 1.55 10.4915 1.55 11.3081 2.01666L16.2581 4.87499C17.0665 5.34999 17.5665 6.20836 17.5665 7.15003Z"
                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <g id="Group">
                    <path id="Vector_2"
                      d="M9.99977 9.16667C11.0721 9.16667 11.9414 8.29734 11.9414 7.22499C11.9414 6.15263 11.0721 5.28336 9.99977 5.28336C8.92742 5.28336 8.05811 6.15263 8.05811 7.22499C8.05811 8.29734 8.92742 9.16667 9.99977 9.16667Z"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3"
                      d="M13.3332 13.8833C13.3332 12.3833 11.8415 11.1667 9.99984 11.1667C8.15817 11.1667 6.6665 12.3833 6.6665 13.8833"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </g>
              </g>
            </svg>
            <p>Perfil</p>
          </a>
          <a (click)="logout()" routerLink=""><img class="icon icon-tabler icon-tabler-user"
              src="../../../../assets/images/icons/logout.svg" alt="user_icon">
            <p>Cerrar sesión</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
