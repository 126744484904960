<div class="atajos">
@if(accountData) {
  <div class="actions">
    <div class="action" (click)="openDialog()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/document-normal.svg" alt="">
        <h3>Detalle de la cuenta</h3>
      </div>
    </div>
    <div class="action" (click)="downloadOwnershipCertificate()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/award.svg" alt="">
        <h3>Certificado de titularidad</h3>
      </div>
    </div>
    <div class="action" (click)="downloadBalanceCertificate()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/award.svg" alt="">
        <h3>Certificado de saldo</h3>
      </div>
    </div>
  </div>
} @else if (cardData) {
  <div class="actions">
      <div class="action-blue"  *ngIf=" cardData.status === 'PRE_ACTIVATED'" (click)="sendActivationSms()">
        <div class="content-action">
          <img src="../../../../assets/images/icons/card-tick.svg" alt="">
          <h3>Activar tarjeta</h3>
        </div>
      </div>
      <div class="action-blue" *ngIf=" cardData.status === 'PENDING_CONTRACT'" (click)="openSignContract(cardData)">
        <div class="content-action">
          <img src="../../../../assets/images/icons/card-tick.svg" alt="">
          <h3>Firmar</h3>
        </div>
      </div>  
    <div class="action" (click)="openCardDetailCard()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/document-normal.svg" alt="">
        <h3>Detalle de la tarjeta</h3>
      </div>
    </div>
    <div class="action" *ngIf=" cardData.status === 'ACTIVATED'" (click)="showPIN()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/eye.svg" alt="">
        <h3>Consultar PIN</h3>
      </div>
    </div>
    <div class="action" *ngIf=" cardData.status === 'ACTIVATED'" (click)="openLimitsDetailCard()">
      <div class="content-action">
        <img src="../../../../assets/images/icons/candle.svg" alt="">
        <h3>Consultar límites</h3>
      </div>
    </div>
  </div>
} @else if (loader) {
  <div class="atajos">
    <div class="skeleton">
      <ngx-skeleton-loader count="1" appearance="line"
        [theme]="{  'background-color': 'var(--brand-palette-50)', height: '54px', 'margin-bottom': '0'}">
      </ngx-skeleton-loader>
    </div>
  </div>
}
</div>