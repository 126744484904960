@if (accountData){
  <div class="dialog_container">
    <fieldset class="fieldset">
      <legend class="legend"> <p class="info_title"><mat-icon>badge</mat-icon>Datos de la cuenta</p></legend> 
      <table class="info_table">
          @for (item of accountTable; track $index) {
              <tr>
                  <th class="table_key">{{item.title}}</th>
                  <td>
                      <p class="td">{{item.value}} </p>
                  </td>
              </tr>
          }
      </table>
    </fieldset>
    <div class="detail-mobile-container">
      <mat-dialog-actions class="closeDialog" align="end">
        <h3 i18n="@@account.data.detail.accounts">Datos de la cuenta</h3>
        <button mat-icon-button mat-dialog-close> <mat-icon> close </mat-icon></button>
        </mat-dialog-actions>
        <div class="accounts-mobile-data-container">
            <div class="table-mobile-HTML">
              <div  class="table-row" *ngFor="let item of accountTable; let index = index" >
                <p class="table-title">{{item.title}}</p>
                <p class="table-value">{{item.value}}</p>
              </div>
            </div>
        </div>
    </div>
  </div>
} @else if (cardData && !isLimits) {
  <div class="dialog_container">
    <fieldset class="fieldset">
      <legend class="legend"> <p class="info_title"><mat-icon>badge</mat-icon>Datos de la tarjeta</p></legend> 
      <table class="info_table">
          @for (item of cardTable; track $index) {
              <tr>
                  <th class="table_key">{{item.title}}</th>
                  <td>
                      <p class="td">{{item.value}} </p>
                  </td>
              </tr>
          }
      </table>
    </fieldset>
    <div class="detail-mobile-container">
      <mat-dialog-actions class="closeDialog" align="end">
        <h3 i18n="@@account.data.detail.accounts">Datos de la tarjeta</h3>
        <button mat-icon-button mat-dialog-close> <mat-icon> close </mat-icon></button>
        </mat-dialog-actions>
        <div class="accounts-mobile-data-container">
            <div class="table-mobile-HTML">
              <div  class="table-row" *ngFor="let item of cardTable; let index = index" >
                <p class="table-title">{{item.title}}</p>
                <p class="table-value">{{item.value}}</p>
              </div>
            </div>
        </div>
    </div>
    <div class="buttons">
      <button class="blue-private-stroked-button" *ngIf="cardData.status !== 'PENDING_CONTRACT'"><p>Descargar contrato</p></button>
      <button class="delete-button" *ngIf="cardData.status === 'ACTIVATED' || cardData.status === 'PENDING_CONTRACT'"
       (click)="openToCancel()"><p>{{ cardData.status === 'PENDING_CONTRACT' ? 'Anular solicitud' : 'Cancelar tarjeta' }}</p></button>
    </div>
  </div>
} @else if (cardData && isLimits) {
  <div class="dialog_container">
    <fieldset class="fieldset">
      <legend class="legend"> <p class="info_title"><mat-icon>badge</mat-icon>Límites</p></legend> 
      <table class="info_table">
          @for (item of limitsTable; track $index) {
              <tr>
                  <th class="table_key">{{item.title}}</th>
                  <td>
                      <p class="td">{{item.value}} </p>
                  </td>
              </tr>
          }
      </table>
    </fieldset>
    <div class="detail-mobile-container">
      <mat-dialog-actions class="closeDialog" align="end">
        <h3 i18n="@@account.data.detail.accounts">Límites</h3>
        <button mat-icon-button mat-dialog-close> <mat-icon> close </mat-icon></button>
        </mat-dialog-actions>
        <div class="accounts-mobile-data-container">
            <div class="table-mobile-HTML">
              <div  class="table-row" *ngFor="let item of limitsTable; let index = index" >
                <p class="table-title">{{item.title}}</p>
                <p class="table-value">{{item.value}}</p>
              </div>
            </div>
        </div>
    </div>
  </div>
}