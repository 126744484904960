import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { allRegex } from 'src/app/core/extensions/regex';
import { ExhibitionContactForm } from 'src/app/core/models/contact/contact';
import { MatDialog } from '@angular/material/dialog';
import { ModalCondicionesUsoComponent } from 'src/app/shared/components/modal-condiciones-uso/modal-condiciones-uso.component';

@Component({
  selector: 'app-exhibition-form',
  templateUrl: './exhibition-form.component.html',
  styleUrl: './exhibition-form.component.scss'
})
export class ExhibitionFormComponent {
  public form: FormGroup;
  @Output() contactData: EventEmitter<ExhibitionContactForm> = new EventEmitter<ExhibitionContactForm>();

  constructor(private fb: FormBuilder, private dialog: MatDialog) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required,Validators.pattern(allRegex.regexPhone)]],
      company: ['', [Validators.required]],
      acceptsDataTreatment: [false, Validators.requiredTrue],
      comments: ['', [Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const body: ExhibitionContactForm = this.form.value;
      body.event = 'infarma';
      this.contactData.emit(body)
    }
  }

  openPersonalDataTreatment() {
        this.dialog.open(ModalCondicionesUsoComponent, {
          data: {contact: 'contact' }
        });
  }
}
