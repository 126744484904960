<div class="table_container">
  <div class="tableMaterial">
    @if (dataSource) {
    <table [class.custom-row]="hideDetail" mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0 table-content">
      <ng-container matColumnDef="accountingDate">
        <th mat-header-cell *matHeaderCellDef> Fecha operación </th>
        <td mat-cell *matCellDef="let element"> <p>{{element.date |date: "dd/MM/yyyy"}} </p></td>
      </ng-container>    
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef i18n="@@type.table.accounts"> Tipo </th>
        <td mat-cell *matCellDef="let element">
          <p>{{element.type == "TRMO" ? "Transferencia" : "Movimiento"}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="concept">
        <th mat-header-cell *matHeaderCellDef> Concepto </th>
        <td mat-cell *matCellDef="let element"> <p class="concept">{{ element.concept }}</p></td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th class="text_right" mat-header-cell *matHeaderCellDef i18n="@@amount.table.accounts"> Importe </th>
        <td class="amount" mat-cell *matCellDef="let element"
          [ngStyle]="{'color': element.amount > 0 ? 'green' : 'red'}"> {{element.amount | currency:
          "€":"symbol":"1.2-2":"es"}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="balance">
          <th class="text_right" mat-header-cell *matHeaderCellDef i18n="@@balance.table.accounts"> Saldo </th>
          <td class="text_right" mat-cell *matCellDef="let element"> {{element.balance |  currency: "€":"symbol":"1.2-2":"es"}} </td>
        </ng-container> -->
      @if(!hideDetail) {
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">Ver</th>
        <td mat-cell *matCellDef="let element">
          <button class="expand" aria-label="expand row">
            <img src="../../../../assets/images/icons/eye-blue.svg" alt="">
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="element-diagram">
              @if (movementDetail) {
              <div class="left-container">
                <div>
                  <button mat-icon-button (click)="downloadMovement(movementDetail)" class="circles"
                    matTooltip="Descargar movimiento"><mat-icon>download</mat-icon></button>
                </div>
              </div>
              <div class="info-table_container">
                <div class="detail_table">
                  <div class="cell" *ngIf="movementDetail.type === 'TRMO' && !this.hideConcept">
                    <h3>Concepto</h3>
                    <p>{{movementDetail.concept}}</p>
                    <!-- <td *ngIf="movementDetail.amount > 0">{{movementDetail.concept}}</td> -->
                  </div>
                  <div class="cell" *ngIf="movementDetail.type === 'TRMO' && movementDetail.amount < 0">
                    <h3>Beneficiario</h3>
                    <p>{{movementDetail.beneficiaryName}}</p>
                  </div>
                  <div class="cell" *ngIf="movementDetail.type === 'TRMO'  && movementDetail.amount > 0">
                    <h3>Nombre del ordenante</h3>
                    <p>{{movementDetail.remitterName}}</p>
                  </div>
                  <div class="cell"
                    *ngIf="getMovementType(movementDetail.type,movementDetail.subscribe, movementDetail.code) === 'Compra tarjeta'">
                    <h3>Establecimiento</h3>
                    <p>en {{movementDetail.establishmentName}}</p>
                  </div>
                  <div class="cell">
                    <h3>Importe</h3>
                    <p>{{movementDetail.amount | currency: "€":"symbol":"1.2-2":"es"}}</p>
                  </div>
                  <div class="cell">
                    <h3>Fecha contable</h3>
                    <p>{{movementDetail.date | date: "dd/MM/yyyy"}}</p>
                  </div>
                  <div class="cell">
                    <h3>Fecha valor</h3>
                    <p>{{movementDetail.operationDate | date: "dd/MM/yyyy"}}</p>
                  </div>
                </div>
              </div>


              }
              @else {
              <lottie-player *ngIf="element == expandedElement" src="../../../../assets/lottie/loader-dots.json"
                background="transparent" speed="0.8" style="width: 100%; height: 140px" loop autoplay id="animacion"
                #animacion></lottie-player>
              }
            </div>
          </div>
        </td>
      </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky: dataSource.data?.length > 0 "></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;  let i = index" class="element-row"
        [class.expanded-row]="expandedElement === row" (click)="$event.stopPropagation(); toggleExpandedRow(row)"></tr>
       @if (!hideDetail) {<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>}
    </table>
    }
  </div>
  @if (loader) {
  <div class="skeleton-container">
    <ngx-skeleton-loader count="2" appearance="line"
      [theme]="{ 'background-color': 'var(--gold-palette-50)', height: '45px' }">
    </ngx-skeleton-loader>
  </div>
  }
</div>