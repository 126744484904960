export class DateRanges {
    static getCurrentMonthRange(): { from: Date; to: Date } {
      const now = new Date();
      const from = new Date(now.getFullYear(), now.getMonth(), 1);
      const to = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { from, to };
    }
  
    static getPreviousMonthRange(): { from: Date; to: Date } {
      const now = new Date();
      const from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const to = new Date(now.getFullYear(), now.getMonth(), 0);
      return { from, to };
    }
  
    static getCurrentQuarterRange(): { from: Date; to: Date } {
      const now = new Date();
      const quarter = Math.floor(now.getMonth() / 3);
      const from = new Date(now.getFullYear(), quarter * 3, 1);
      const to = new Date(now.getFullYear(), quarter * 3 + 3, 0);
      return { from, to };
    }
  
    static getPreviousQuarterRange(): { from: Date; to: Date } {
      const now = new Date();
      let year = now.getFullYear();
      let quarter = Math.floor(now.getMonth() / 3) - 1;
  
      if (quarter < 0) {
        quarter = 3; 
        year -= 1;
      }
  
      const from = new Date(year, quarter * 3, 1);
      const to = new Date(year, quarter * 3 + 3, 0);
      return { from, to };
    }
  
    static formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`; // formato 'yyyy-MM-dd'
    }
  }
  