import { ProgressComponent } from './../modules/landing/employment/progress/progress.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { QuickActionsComponent } from './components/quick-actions/quick-actions.component';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserComponent } from './components/browser/browser.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderPrivateComponent } from './layout/header-private/header-private.component';
import { HeaderPublicComponent } from './layout/header-public/header-public.component';
import { NavBarComponent } from './layout/nav-bar/nav-bar.component';
import { MaterialModule } from './material.module';
import { FilterComponent } from './components/filter/filter.component';
import { SwitchLangComponent } from './components/switch-lang/switch-lang.component';
import { PhoneValidationComponent } from './components/phone-validation/phone-validation.component';
import { PlansComponent } from './components/plans/plans.component';
import { PlanCardComponent } from './components/plans/plan-card/plan-card.component';
import { ComparisonTableComponent } from './components/comparison-table/comparison-table.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SelectAccountComponent } from './components/select-account/select-account.component';
import { SenderComponent } from './components/sender/sender.component';
import { FaqComponent } from './components/faq/faq.component';
import { LoginFormComponent } from './layout/login-form/login-form.component';
import { LoginBusinessComponent } from './layout/login-business/login-business.component';
import { ChartComponent } from './components/chart/chart.component';
import { FormModalComponent } from './components/form-modal/form-modal.component';
import { ManagerModalComponent } from './components/manager-modal/manager-modal.component';
import { MyProductsCardComponent } from './components/my-products-card/my-products-card.component';
import { ModalAdminTeamComponent } from './components/modal-admin-team/modal-admin-team.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { preventLetters } from '../core/extensions/functions';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { ModalCondicionesUsoComponent } from './components/modal-condiciones-uso/modal-condiciones-uso.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { HelpSharedComponent } from './components/help-shared/help-shared.component';
import { MovementsTableComponent } from './components/movements-table/movements-table.component';
import { ExhibitionFormComponent } from '../modules/exhibition-stand-landing/exhibition-form/exhibition-form.component';
import { ExhibitionStandLandingComponent } from '../modules/exhibition-stand-landing/exhibition-stand-landing.component';
import { FormSentComponent } from "../modules/exhibition-stand-landing/form-sent/form-sent.component";
import { FilterTagsComponent } from './components/filter-tags/filter-tags.component';
declare let AOS: any;

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};


@NgModule({
  declarations: [
    FooterComponent,
    HeaderPublicComponent,
    HeaderPrivateComponent,
    BrowserComponent,
    NavBarComponent,
    FilterComponent,
    SwitchLangComponent,
    QuickActionsComponent,
    ErrorPageComponent,
    SpinnerComponent,
    UploadFileComponent,
    PhoneValidationComponent,
    SideNavComponent,
    AlertsComponent,
    PlansComponent,
    PlanCardComponent,
    ComparisonTableComponent,
    UnderConstructionComponent,
    SelectAccountComponent,
    SenderComponent,
    FaqComponent,
    LoginFormComponent,
    LoginBusinessComponent,
    ChartComponent,
    FormModalComponent,
    ManagerModalComponent,
    MyProductsCardComponent,
    ModalAdminTeamComponent,
    UserDocumentsComponent,
    ResetPasswordComponent,
    ProgressComponent,
    LegalNoticeComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    ConfirmModalComponent,
    HelpSharedComponent,
    MovementsTableComponent,
    ExhibitionFormComponent,
    ExhibitionStandLandingComponent,
    FilterTagsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    PdfViewerModule,
    ModalCondicionesUsoComponent,
    FormSentComponent
],
  exports: [
    MaterialModule,
    HeaderPublicComponent,
    HeaderPrivateComponent,
    FooterComponent,
    BrowserComponent,
    NavBarComponent,
    HttpClientModule,
    FormsModule,
    FilterComponent,
    ReactiveFormsModule,
    QuickActionsComponent,
    ErrorPageComponent,
    SpinnerComponent,
    UploadFileComponent,
    PhoneValidationComponent,
    SideNavComponent,
    AlertsComponent,
    PlansComponent,
    ComparisonTableComponent,
    UnderConstructionComponent,
    NgxSkeletonLoaderModule,
    SelectAccountComponent,
    SenderComponent,
    FaqComponent,
    LoginFormComponent,
    LoginBusinessComponent,
    ChartComponent,
    PdfViewerModule,
    UserDocumentsComponent,
    PdfViewerModule,
    ModalCondicionesUsoComponent,
    ResetPasswordComponent,
    ProgressComponent,
    LegalNoticeComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    ConfirmModalComponent,
    HelpSharedComponent,
    MovementsTableComponent,
    ExhibitionFormComponent,
    ExhibitionStandLandingComponent,
    FilterTagsComponent
  ],


  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    CurrencyPipe,
    {provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    FilterComponent,
    SwitchLangComponent,
    DatePipe,
  ]
})
export class SharedModule {

}
